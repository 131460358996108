import { getRestHeaders } from '@src/utils/network';
import getConfig from 'next/config';

const { API_HOST } = getConfig().publicRuntimeConfig;

interface FBSignupParams {
  email?: string;
  facebook_user_id: string;
  facebook_access_token: string;
  signup?: boolean;
  nurse_type?: string;
  signup_source?: string;
  signup_source_qualifier?: string;
  initial_referring_domain?: string;
  referrer_code?: string[];
  referral_code?: string;
  role_id: string | null;
  first_name: string;
  last_name: string;
  gclid?: string;
  gbraid?: string;
  wbraid?: string;
}

interface AppleSignUpParams {
  identity_token: string;
  signup: boolean | undefined;
  first_name: string;
  last_name: string;
  role_id: string | null;
  gclid?: string;
  gbraid?: string;
  wbraid?: string;
}

interface GoogleSignUpParams {
  google_access_token: string;
  signup?: boolean;
  nurse_type?: string;
  role_id: string | null;
  first_name: string;
  last_name: string;
  gclid?: string;
  gbraid?: string;
  wbraid?: string;
}

interface OpenIdProfile {
  email?: string;
  given_name?: string;
  family_name?: string;
  sub: string;
}

export interface GetOpenIdResult {
  profile: OpenIdProfile;
  access_token: string;
}

interface YahooSignUpProfileParams {
  profile: OpenIdProfile;
  access_token: string;
  signup?: boolean;
  nurse_type?: string;
  signup_source?: string;
  signup_source_qualifier?: string;
  referrer_code?: string[];
  referral_code?: string;
  initial_referring_domain?: string;
  role_id: string | null;
  first_name: string;
  last_name: string;
}

interface YahooSignUpParams {
  code: string;
  signup?: boolean;
  nurse_type?: string;
  signup_source?: string;
  signup_source_qualifier?: string;
  referrer_code?: string[];
  referral_code?: string;
  initial_referring_domain?: string;
  role_id: string | null;
  first_name: string;
  last_name: string;
  gclid?: string;
  gbraid?: string;
  wbraid?: string;
}

interface YahooGetOpenIdProfileParams {
  code?: string;
}

export interface CommonErrorResult {
  error: string;
}

// Auth result from the trusted API
interface FBAuthErrorResult {
  fb_error: string;
}

interface AppleAuthErrorResult {
  apple_error: string;
}

interface GoogleAuthErrorResult {
  google_error: string;
}
interface YahooAuthErrorResult {
  yahoo_error: string;
}

export interface AuthSuccessResult {
  uuid: string;
  email: string;
  first_name: string;
  last_name: string;
  auth_token: string;
  intercom_user_hash: string;
  membership_type: string;
}

export type FBAuthAPIResult =
  | CommonErrorResult
  | FBAuthErrorResult
  | AuthSuccessResult;
export type AppleAuthAPIResult =
  | CommonErrorResult
  | AppleAuthErrorResult
  | AuthSuccessResult;
export type GoogleAuthAPIResult =
  | CommonErrorResult
  | GoogleAuthErrorResult
  | AuthSuccessResult;
export type YahooAuthAPIResult =
  | CommonErrorResult
  | YahooAuthErrorResult
  | AuthSuccessResult;

export async function signUpFb(body: FBSignupParams) {
  const res = await fetch(`${API_HOST}/facebook_auth`, {
    method: 'POST',
    headers: getRestHeaders(),
    body: JSON.stringify(body),
  });
  return (await res.json()) as FBAuthAPIResult;
}

export async function signUpApple(body: AppleSignUpParams) {
  return fetch(`${API_HOST}/mobile/apple_auth`, {
    method: 'POST',
    headers: getRestHeaders(),
    body: JSON.stringify(body),
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else if (response.status === 204) {
      throw new Error('apple account not found');
    }

    throw new Error('signUpApple failed');
  });
}

export async function signUpGoogle(body: GoogleSignUpParams) {
  return fetch(`${API_HOST}/google_auth`, {
    method: 'POST',
    headers: getRestHeaders(),
    body: JSON.stringify(body),
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else if (response.status === 204) {
      throw new Error('google account not found');
    }
    throw new Error('signUpGoogle failed');
  });
}

export async function signUpYahoo(
  body: YahooSignUpParams | YahooSignUpProfileParams
) {
  const res = await fetch(`${API_HOST}/yahoo_auth`, {
    method: 'POST',
    headers: getRestHeaders(),
    body: JSON.stringify(body),
  });
  return (await res.json()) as YahooAuthAPIResult;
}

export async function getOpenIdProfileYahoo(
  queryParams: YahooGetOpenIdProfileParams
) {
  const res = await fetch(
    `${API_HOST}/yahoo_profile?code=${queryParams.code}`,
    {
      method: 'GET',
      headers: getRestHeaders(),
    }
  );
  return (await res.json()) as GetOpenIdResult;
}

// Response structure from https://github.com/trusted/trusted-api/blob/6c923da/app/controllers/files_controller.rb
interface UploadedFileResponse {
  uuid: string;
  filename: string;
  download_url: string;
  view_url: string;
  preview_url: string;
  created_at: string;
  content_type: string;
}
interface UploadedFileError {
  errors: string;
}

type FileUploadResponse = { files: UploadedFileResponse[] } | UploadedFileError;

/**
 * @deprecated Use dedicated GraphQL mutations
 */
export const uploadFiles = async (files: File[]) => {
  const formData = new FormData();

  const headers = getRestHeaders();
  delete headers['Content-Type']; // Needs to be set by fetch

  for (let i = 0; i < files.length; i++) {
    formData.append('files[]', files[i]);
  }

  const res = await fetch(`${API_HOST}/files`, {
    method: 'POST',
    headers,
    body: formData,
  });

  return res.json() as Promise<FileUploadResponse>;
};
