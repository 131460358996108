import AppleSignin from 'react-apple-signin-auth';
import { useRef } from 'react';

import { primarySignupFormVar } from '@src/apollo/local';
import { type AppleAuthAPIResult, signUpApple } from '@src/apiCalls';
import { SSOButton } from '@src/components/sso-buttons/sso-button';
import { getClickAdIds } from '@src/utils/gclid';

type Props = {
  success: (result: AppleAuthAPIResult) => void;
  signup?: boolean;
  onFail?: (error: string) => void;
  redirectURL?: string;
  appleAccountNotFound?: () => void;
  viewer?: {
    firstName: string;
    lastName: string;
  };
};
/** Apple Signin button */
export const AppleButton = ({
  success,
  redirectURL,
  signup,
  viewer,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onFail = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  appleAccountNotFound = () => {},
}: Props) => {
  const anchorRef = useRef<Element>(null);
  const { nurseProfile } = primarySignupFormVar();
  const errorMessage =
    'Could not sign up using Apple. Please try signing up with your email instead.';

  return (
    <AppleSignin
      ref={anchorRef}
      authOptions={{
        clientId: 'com.trustedhealth.web',
        scope: 'name email',
        redirectURI: redirectURL,
        usePopup: true,
      }}
      uiType="dark"
      className="apple-auth-btn"
      render={(props: { onClick: () => void }) => (
        <SSOButton provider="apple" onClick={props.onClick} />
      )}
      onError={() => onFail(errorMessage)}
      onSuccess={(response: { authorization: any; user: any }) => {
        const { gclid, gbraid, wbraid } = getClickAdIds();
        const authorization = response.authorization;
        const user = response.user;
        const params = {
          identity_token: authorization?.id_token || '',
          signup: signup,
          first_name: viewer?.firstName || user?.name?.firstName || '',
          last_name: viewer?.lastName || user?.name?.lastName || '',
          role_id: nurseProfile.roleId,
          gclid,
          gbraid,
          wbraid,
        };

        signUpApple(params)
          .then(result => {
            localStorage.setItem('LAST_USED_SSO', 'apple');
            success(result);
          })
          .catch(e => {
            if (e instanceof Error && e.message === 'apple account not found') {
              appleAccountNotFound?.();
            }

            onFail?.(errorMessage);
          });
      }}
    />
  );
};
